import { defineStore } from "pinia";
import { ref, reactive } from "vue";
import { ToastType } from "~/types/general";

export const useLoyaltyPointsStore = defineStore("loyalty-points", () => {
  // State
  const loyaltyPointsRules = ref([]);
  const loyaltyPoints = ref([]);
  const pagination = reactive({
    page: 1,
    perPage: 40,
  });
  const totalPages = ref(null);
  const totalLoyaltyPoints = ref(null);
  const totalPaid = ref(null);
  const isOpenSidebar = ref(false);
  const filters = reactive({
    selectedDate: "",
    selectedDateRange: {
      start: "",
      end: "",
    },
    search: "",
    letter: "",
  });
  const filtersOptions = reactive({
    id: {
      type: "id",
      title: "Customers",
      subtitle: "Leave blank to view all customers",
      options: [],
      chosenOptions: [],
    },
    status: {
      type: "status",
      title: "Customers Status",
      subtitle: "Leave blank to view all statuses",
      options: [],
      chosenOptions: [],
    },
    store: {
      type: "store",
      title: "Location",
      subtitle: "Leave blank to view all locations",
      options: [],
      chosenOptions: [],
    },
  });
  const files = ref([]);
  const customersLoyaltyPoints = ref([]);
  const customersLoyaltyPointsMeta = ref(null);
  const pageTable = ref(1);
  const perPageTable = ref(10);

  // Loyalty Points Rules Actions
  async function getLoyaltyPointsRules() {
    let response;
    useIsLoading(true);
    try {
      response = await useVaniloApi("/loyalty-rewards");
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    useIsLoading(false);
    loyaltyPointsRules.value = response?.data;
    return response;
  }

  async function createLoyaltyPointsRule(loyaltyPointsRule) {
    let response;
    useIsLoading(true);
    try {
      response = await useVaniloApi("/loyalty-rewards", {
        method: "POST",
        body: loyaltyPointsRule,
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    useIsLoading(false);
    return response;
  }

  async function updateLoyaltyPointsRule(loyaltyPointsRule) {
    let response;
    useIsLoading(true);
    try {
      response = await useVaniloApi(
        `/loyalty-rewards/${loyaltyPointsRule.id}`,
        {
          method: "POST",
          body: loyaltyPointsRule,
        }
      );
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    useIsLoading(false);
    return response;
  }

  async function deleteLoyaltyPointsRule(loyaltyPointsRuleId) {
    let response;
    useIsLoading(true);
    try {
      response = await useVaniloApi(`/loyalty-rewards/${loyaltyPointsRuleId}`, {
        method: "DELETE",
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    useIsLoading(false);
    return response;
  }

  // Loyalty Points Actions
  async function getLoyaltyPoints(payload) {
    let response;
    const params = {
      page: pagination.page,
      limit: pagination.perPage,
      ...(payload && payload),
    };
    useIsLoading(true);
    try {
      response = await useVaniloApi("/customers/loyalty-rewards", { params });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    useIsLoading(false);
    loyaltyPoints.value =
      response.meta.current_page === 1
        ? response?.data
        : [...loyaltyPoints.value, ...response?.data];
    pagination.page = response.meta.current_page;
    totalLoyaltyPoints.value = response.meta.total;
    totalPages.value = response.meta.last_page;
    totalPaid.value = response.meta.total_paid;
    return response;
  }

  async function getCustomersLoyaltyPoints(payload) {
    let response;
    const customerId = payload.customersId;
    delete payload.customersId;
    const params = { ...(payload && payload) };
    useIsLoading(true);
    try {
      response = await useVaniloApi(
        `/customers/${customerId}/notes?filter[loyaltyPoints]=true`,
        { params }
      );
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    useIsLoading(false);
    customersLoyaltyPoints.value = response?.data;
    customersLoyaltyPointsMeta.value = response.meta;
    return response;
  }

  async function addRemoveCustomersLoyaltyPoints(payload) {
    useIsLoading(true);
    try {
      const response = await useVaniloApi(
        `/customers/${payload.customersId}/loyalty-points`,
        {
          method: "POST",
          body: payload.body,
        }
      );

      return response;
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    } finally {
      useIsLoading(false);
    }
  }

  async function updateCustomersLoyaltyPoints(payload) {
    useIsLoading(true);
    try {
      const response = await useVaniloApi(`customers/notes/${payload.noteId}`, {
        method: "POST",
        body: payload.body,
      });

      return response;
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    } finally {
      useIsLoading(false);
    }
  }

  // Customer Notifications Actions
  async function createCustomerNotification(payload) {
    const customerId = payload.customerId;
    delete payload.customerId;

    useIsLoading(true);
    try {
      const response = await useVaniloApi(
        `/notifications/send-custom/${customerId}`,
        {
          method: "POST",
          body: payload,
        }
      );

      useToast(
        {
          message: "Yay! Successfully sent!",
          submessage: "Notification to the customer has been sent",
        },
        {
          type: ToastType.SUCCESS,
          duration: 3000,
        }
      );

      return response;
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    } finally {
      useIsLoading(false);
    }
  }

  return {
    // State
    loyaltyPointsRules,
    loyaltyPoints,
    pagination,
    totalPages,
    totalLoyaltyPoints,
    totalPaid,
    isOpenSidebar,
    filters,
    filtersOptions,
    files,
    customersLoyaltyPoints,
    customersLoyaltyPointsMeta,
    pageTable,
    perPageTable,

    // Actions
    getLoyaltyPointsRules,
    createLoyaltyPointsRule,
    updateLoyaltyPointsRule,
    deleteLoyaltyPointsRule,
    getLoyaltyPoints,
    getCustomersLoyaltyPoints,
    addRemoveCustomersLoyaltyPoints,
    updateCustomersLoyaltyPoints,
    createCustomerNotification,
  };
});
